
import icons from '@/common/utils/icons.json';

export default {
  name: 'Icon',
  props: {
    name: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    icons,
  }),
};
